
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import TrainningInstituteModal from '@/layout/header/partials/trainning_institute/trainning_institute_drawer.vue';
// import TargetListModal from "@/components/modals/forms/association/TargetListModal.vue";
import OrganizationModal from '@/components/modals/forms/settings/OrganizationModal.vue';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { format } from 'date-fns';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'institute-list',
  components: {
    TrainningInstituteModal,
    Form,
    Datatable,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      actionApprove: false,
      actionEdit: false,
      action: false,
      data: {},
      association: [] as any,
      TpartnerData: [] as any,
      instituteTypes: [],
      industry: [],
      optionsTPartner: [] as any,
      divisions: [],
      companies: [] as any,
      subDistricts: [],
      empDistricts: [],
      companyList: [],
      active_status: 0,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '300px',
        },
        {
          name: 'SL',
          key: 'sl',
          sortable: false,
        },
        {
          name: 'Logo',
          key: 'company_logo',
          sortable: true,
        },
        {
          name: 'Company Name',
          key: 'company_name',
          sortable: true,
          width: '200px',
        },
         {
          name: 'Short Name',
          key: 'short_name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Address',
          key: 'address',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },

        {
          name: 'Telephone',
          key: 'telephone',
          sortable: true,
        },
        {
          name: 'Web Url',
          key: 'web',
          sortable: true,
        }, 
         {
          name: 'Create Date',
          key: 'create',
          sortable: true,
        },
      ],
      adminCheck: false,
      loading: false,
      load: false,
      tranches: [],
      componentKey: 0,
      instituteInfos: false,
      TpartnerInfos: [] as any,
      showinstitutionList: false,
      showSearchBtn: false,
      showtrainingProviderNotice: false,
      api_url: 'http://localhost:8000',

      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
    };
  },
  async created() {
    await this.getCompany();
    await this.actioncheck();
    this.emitter.on('employee-updated', async () => {
      this.getCompany();
    });
  },

  methods: {
    async actioncheck() {
      let role = VueCookieNext.getCookie('_cpv_role_id');
      if (role == 1 || role == 2) {
        this.action = true;
      }
    },

     formatDate(dateString) {
      return dateString ? format(new Date(dateString), 'dd-MM-yyyy') : '';
    },
    async getCompany() {
      let data = '';
      await ApiService.get('company/list?company=' + data)
        .then((response) => {
          this.companyList = response.data.data;
          this.showinstitutionList = true;
         this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    checkAdmin() {
      if (VueCookieNext.getCookie('_seip_entity_type') == 1004) {
        let user = VueCookieNext.getCookie('_seip_user');
        console.log(
          user.employee.entity_organogram[0].entitytyperole.role_title
        );
        if (
          user.employee.entity_organogram[0].entitytyperole.role_title ==
          'Admin'
        ) {
          this.adminCheck = true;
        }
      }
    },





    searchBtnActive() {
      this.showSearchBtn = true;
    },

    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    edit(data) {
      this.emitter.emit('institute-edit-modal-data', data);
    },
    view(institute) {
      this.data = institute;
    },

    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('company/delete/' + `${id}`)
            .then((response) => {
              if (response.data.status == 'error') {
                this.load = false;
                Swal.fire({
                  title: 'Error!',
                  html: response.data.message,
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Close',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                });
              } else {
                Swal.fire('Deleted!', response.data.data, 'success').then(() => {
                  this.emitter.emit('employee-updated', true);
                });
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    
    Active(id) {
      Swal.fire({
        title: 'Are you sure you want to Active it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Activated!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'company/status-change?company_id=' + id + '&active_status=1'
          )
            .then((response) => {
              //this.courseInfo();
              Swal.fire('Activated!', response.data.message, 'success');
              this.emitter.emit('employee-updated', true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Inactive(id) {
      Swal.fire({
        title: 'Are you sure you want to Inactive it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Inactivate!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'company/status-change?company_id=' + id + '&active_status=3'
          )
            .then((response) => {
              //this.courseInfo();
              Swal.fire('Inactivated!', response.data.message, 'success');
              this.emitter.emit('employee-updated', true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const InstituteSchema = [];
    return {
      InstituteSchema,
    };
  },
});
